import React, { useEffect, useState } from 'react';
import './OrganizationListComponent.css';
import {
    Table,
    TableHead,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Button,
  } from "@tremor/react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Organization } from '../../interfaces/AccessInterfaces';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

export default function ClientListComponent() {
    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [requested, setRequested] = useState<boolean>(false);

    const handleSubmit = () => {
        const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;
        const oauth = "Bearer " + token;
        axios.get(`${baseURL}/organizations/clients`,
          {headers: {accept: 'application/json', "Authorization": oauth}}
        )
        .then((res)=>{
          console.log(res);
          setOrganizations(res.data.data);
        })
        .catch((err)=>{
          console.log(err);
        })
    }

    useEffect(()=>{
        if (!requested){
          handleSubmit();
          setRequested(true);
        }
      },[requested])

  return (
    <div>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>ID organização</TableHeaderCell>
                    <TableHeaderCell className="text-left">CNPJ</TableHeaderCell>
                    <TableHeaderCell className="text-left">Nome</TableHeaderCell>
                    <TableHeaderCell className="text-left">Perfil</TableHeaderCell>
                    <TableHeaderCell className="text-left">Alocar transportadoras</TableHeaderCell>
                </TableRow>
            </TableHead>

            <TableBody>
                { organizations
                    .map((organization) => (
                        <TableRow key={ organization.id_org }>
                            <TableCell className="TableField">{ organization.id_org }</TableCell>
                            <TableCell className="TableField">{ organization.cnpj }</TableCell>
                            <TableCell className="TableField">{ organization.name }</TableCell>
                            <TableCell className="TableField">{ organization.profile }</TableCell>
                            <Link to={`/transporter_allocation/${organization.id_org}`}>
                              <Button className='OpenOrderBtn' icon={ArrowUpRightIcon} />
                            </Link>
                        </TableRow>
                    )) }
            </TableBody>
        </Table>
    </div>
  );
}