import React, { useState } from 'react';
import {
  Card,
  TabList,
  Tab,
} from "@tremor/react";
import SidebarComponent from '../../components/SidebarComponent';
import TitleComponent from "../../components/TitleComponent";
import './ListAccessPage.css';
import UserListComponent from '../../components/accessComponents/UserListComponent';
import OrganizationListComponent from '../../components/accessComponents/OrganizationListComponent';
import TransporterListComponent from '../../components/accessComponents/TransporterListComponent';
import ClientListComponent from '../../components/accessComponents/ClientListComponent';

export default function ListAccessPage() {
  const [showCard, setShowCard] = useState("0");

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="OpsViews">
          <TabList
            defaultValue="0"
            onValueChange={(value) => setShowCard(value)}
          >
            <Tab value="0" text="Organizações" className={showCard === "0" ? 'SelectedTab' : 'OtherTab'}/>
            <Tab value="1" text="Usuários" className={showCard === "1" ? 'SelectedTab' : 'OtherTab'}/>
            <Tab value="2" text="Transportadoras" className={showCard === "2" ? 'SelectedTab' : 'OtherTab'}/>
            <Tab value="3" text="Alocar transportadoras" className={showCard === "3" ? 'SelectedTab' : 'OtherTab'}/>
          </TabList>

          <Card className="mt-6">
            {showCard === "0" ? (
              <OrganizationListComponent />
            ) : ("")}
            {showCard === "1" ? (
              <UserListComponent />
            ) : ("")}
            {showCard === "2" ? (
              <TransporterListComponent />
            ) : ("")}
            {showCard === "3" ? (
              <ClientListComponent />
            ) : ("")}
            
          </Card>
        </div>
      </div>
    </main>
  );
}