import React, { useState } from 'react';
import './CreateOrganizationsPage.css';
import { 
    Button,
    Text,
    Grid,
    Col,
    Title,
    TextInput,
    Dropdown,
    DropdownItem,
    Card,
    Toggle,
    ToggleItem 
} from "@tremor/react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Organization, CorreiosOrgData } from '../../interfaces/AccessInterfaces';
import SidebarComponent from '../../components/SidebarComponent';
import TitleComponent from "../../components/TitleComponent";

export default function CreateOrganizationsPage() {
    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const oauth = "Bearer " + token;

    const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;

    const baseOrganization = {
        cnpj: "",
        prefix: "",
        profile: "CLIENT",
        street_number: "",
        state: "SP",
        telephone: "",
        email_org: "",
        name: "",
        product_flag: false,
        whatsapp_flag: false,
        email_flag: false,
        spreadsheet: false,
        correios: false,
        street: "",
        neighborhood: "",
        city: "",
        zip: "",
        address_number: ""
    }

    let baseCorreiosOrgData = {
        id_org: 1,
        api_key: "",
        cartao_postagem: "",
        document: "",
        contract_number: ""
    }

    const [requestProcessing, setRequestProcessing] = useState(false);
    const [organization, setOrganization] = useState<Organization>(baseOrganization);
    const [useMazelogCorreiosContract, setUseMazelogCorreiosContract] = useState<boolean>(true);
    const [correiosOrgData, setCorreiosOrgData] = useState<CorreiosOrgData>(baseCorreiosOrgData);

    const navigate = useNavigate();
    const createOrg = () => {
        return axios.post(`${baseURL}/organizations`,
                organization,
                {headers: {
                    "Content-Type": "application/json",
                    "Authorization": oauth,
                }});
    }

    const createOrgCorreiosData = (orgData: CorreiosOrgData) => {
        if (orgData.id_org !== 1) {
            axios.post(`${baseURL}/correios`,
                orgData,
                {headers: {
                    "Content-Type": "application/json",
                    "Authorization": oauth,
                }})
            .then((res) => {
                alert("Organização criada com sucesso!");
                navigate('/access')
            })
            .catch((err) => {
                alert('Erro!');
                setRequestProcessing(false);
            })
        }
    }

    const createMazelogCorreiosData = (createdIdOrg: number) => {
        axios.get(`${baseURL}/correios`,
            {headers: {
                "Content-Type": "application/json",
                "Authorization": oauth,
            }})
        .then((res) => {
            createOrgCorreiosData({...res.data, id_org: createdIdOrg});
        })
    }

    const createTransporterServiceAreaData = (createdIdOrg: number) => {
        axios.post(`${baseURL}/transporters/service_areas`,
            {
                id_org: createdIdOrg,
                transporter_name: organization.name,
                service_zip_ranges: [["00000-000", "01000-000"]]
            },
            {headers: {
                "Content-Type": "application/json",
                "Authorization": oauth,
        }})
        .then((res) => {
            alert("Organização criada com sucesso!");
            navigate('/access')
        })
    }

    const createOrgTransporterAllocation = (createdIdOrg: number) => {
        axios.post(`${baseURL}/transporters/transporter_allocation`,
            {
                id_org: createdIdOrg,
                transporter_allocation: [
                    ["flashExpress", "", ""],
                    ["", "", ""],
                    ["", "", ""]
                ]
            },
            {headers: {
                "Content-Type": "application/json",
                "Authorization": oauth,
        }})
    }

  const handleSubmit = async () => {
    setRequestProcessing(true);
    createOrg()
    .then((res) => {
        if (organization.profile === "CLIENT") {
            createOrgTransporterAllocation(res?.data?.id_org)
            if (organization.correios) {
                if (useMazelogCorreiosContract) {
                    createMazelogCorreiosData(res?.data?.id_org);
                } else {
                    createOrgCorreiosData({...correiosOrgData, id_org: res?.data?.id_org});
                }
            } else {
                alert("Organização criada com sucesso!");
                navigate('/access')
            }
        } else if (organization.profile === "TRANSPORTER") {
            createTransporterServiceAreaData(res?.data?.id_org)
        } else {
            alert("Organização criada com sucesso!");
            navigate('/access')
        }
    })
    .catch((err) => {
        alert('Erro!');
        setRequestProcessing(false);
    })
  }

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="CreateUserViews">
            <Card>
                <Title>Criar organização</Title>
                <Grid numCols={2} className="gap-2">
                    <Col numColSpan={1}>
                        <Text>CNPJ</Text>
                        <TextInput 
                        className='max-w-md'
                        error={organization.cnpj.length !== 14}
                        placeholder='00000000000000'
                        onChange={(e) => {
                            setOrganization({...organization, cnpj: e.target.value})
                            setCorreiosOrgData({...correiosOrgData, document: e.target.value})
                        }}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Nome</Text>
                        <TextInput 
                        className='max-w-md'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            name: e.target.value
                        })}}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Prefixo</Text>
                        <TextInput
                        className='max-w-md'
                        error={organization.prefix.length !== 3}
                        placeholder='ABC'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            prefix: e.target.value.toUpperCase()
                        })}}
                        />
                    </Col>
                    <Col>
                        <Text>Perfil</Text>
                        <Dropdown
                        className='max-w-md'
                        defaultValue="CLIENT"
                        onValueChange={(value) => {
                            setOrganization({...organization, profile:value})
                        }}
                        >
                            <DropdownItem value="CLIENT" text="CLIENT" />
                            <DropdownItem value="CORP" text="CORP" />
                            <DropdownItem value="TRANSPORTER" text="TRANSPORTER" />
                        </Dropdown>
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Telefone</Text>
                        <TextInput
                        className='max-w-md'
                        placeholder='11999999999'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            telephone: e.target.value
                        })}}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Email</Text>
                        <TextInput
                        className='max-w-md'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            email_org: e.target.value
                        })}}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>CEP</Text>
                        <TextInput
                        className='max-w-md'
                        placeholder='00000000'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            zip: e.target.value
                        })}}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Logradouro</Text>
                        <TextInput
                        className='max-w-md'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            street: e.target.value
                        })}}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Número</Text>
                        <TextInput
                        className='max-w-md'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            street_number: e.target.value
                        })}}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Complemento</Text>
                        <TextInput
                        className='max-w-md'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            address_number: e.target.value
                        })}}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Bairro</Text>
                        <TextInput
                        className='max-w-md'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            neighborhood: e.target.value
                        })}}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Cidade</Text>
                        <TextInput
                        className='max-w-md'
                        onChange={(e) => {setOrganization({
                            ...organization,
                            city: e.target.value
                        })}}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Estado</Text>
                        <Dropdown
                            className='max-w-md'
                            defaultValue="SP"
                            onValueChange={(value) => {setOrganization({
                                ...organization,
                                state: value
                            })}}
                        >
                            <DropdownItem value="AC" text="Acre" />
                            <DropdownItem value="AL" text="Alagoas" />
                            <DropdownItem value="AP" text="Amapá" />
                            <DropdownItem value="AM" text="Amazonas" />
                            <DropdownItem value="BA" text="Bahia" />
                            <DropdownItem value="CE" text="Ceará" />
                            <DropdownItem value="DF" text="Distrito Federal" />
                            <DropdownItem value="ES" text="Espírito Santo" />
                            <DropdownItem value="GO" text="Goiás" />
                            <DropdownItem value="AL" text="Alagoas" />
                            <DropdownItem value="MA" text="Maranhão" />
                            <DropdownItem value="MT" text="Mato Grosso" />
                            <DropdownItem value="MS" text="Mato Grosso do Sul" />
                            <DropdownItem value="MG" text="Minas Gerais" />
                            <DropdownItem value="PA" text="Pará" />
                            <DropdownItem value="PB" text="Paraíba" />
                            <DropdownItem value="PR" text="Paraná" />
                            <DropdownItem value="PE" text="Pernambuco" />
                            <DropdownItem value="PI" text="Piauí" />
                            <DropdownItem value="RJ" text="Rio de Janeiro" />
                            <DropdownItem value="RN" text="Rio Grande do Norte" />
                            <DropdownItem value="RS" text="Rio Grande do Sul" />
                            <DropdownItem value="RO" text="Rondônia" />
                            <DropdownItem value="RR" text="Roraima" />
                            <DropdownItem value="SC" text="Santa Catarina" />
                            <DropdownItem value="SP" text="São Paulo" />
                            <DropdownItem value="SE" text="Sergipe" />
                            <DropdownItem value="TO" text="Tocantins" />
                        </Dropdown>
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Produto na etiqueta</Text>
                        <input
                            type='checkbox'
                            checked={organization.product_flag}
                            onChange={() => setOrganization({
                                ...organization,
                                product_flag: !organization.product_flag
                            })}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Criação via planilha</Text>
                        <input
                            type='checkbox'
                            checked={organization.spreadsheet}
                            onChange={() => setOrganization({
                                ...organization,
                                spreadsheet: !organization.spreadsheet
                            })}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Disparo WhatsApp</Text>
                        <input
                            type='checkbox'
                            checked={organization.whatsapp_flag}
                            onChange={() => setOrganization({
                                ...organization,
                                whatsapp_flag: !organization.whatsapp_flag
                            })}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Disparo Email</Text>
                        <input
                            type='checkbox'
                            checked={organization.email_flag}
                            onChange={() => setOrganization({
                                ...organization,
                                email_flag: !organization.email_flag
                            })}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Correios</Text>
                        <input
                            type='checkbox'
                            checked={organization.correios}
                            onChange={() => setOrganization({
                                ...organization,
                                correios: !organization.correios
                            })}
                        />
                    </Col>
                </Grid>
            {
                !organization.correios ? "" :
                    <div>
                        <Text>Contrato Correios Mazelog</Text>
                        <Toggle
                            defaultValue={useMazelogCorreiosContract ? "1" : "2"}
                            onValueChange={(value) => setUseMazelogCorreiosContract(value === "1")}
                            color='purple'
                        >
                            <ToggleItem value="1" text="Contrato Mazelog" />
                            <ToggleItem value="2" text="Contrato próprio" />
                        </Toggle>
                    </div>
            }
            {organization.correios && !useMazelogCorreiosContract ? (
                <div>
                    <Grid numCols={1}>
                        <Col numColSpan={1}>
                            <Text>Número de contrato</Text>
                            <TextInput
                            className='max-w-md'
                            error={!correiosOrgData.contract_number}
                            onChange={(e) => {setCorreiosOrgData({
                                ...correiosOrgData,
                                contract_number: e.target.value
                            })}}
                            />
                        </Col>
                        <Col numColSpan={1}>
                            <Text>Número do cartão de postagem</Text>
                            <TextInput
                            className='max-w-md'
                            error={!correiosOrgData.cartao_postagem}
                            onChange={(e) => {setCorreiosOrgData({
                                ...correiosOrgData,
                                cartao_postagem: e.target.value
                            })}}
                            />
                        </Col>
                        <Col numColSpan={1}>
                            <Text>Chave da API</Text>
                            <TextInput
                            className='max-w-md'
                            error={!correiosOrgData.api_key}
                            onChange={(e) => {setCorreiosOrgData({
                                ...correiosOrgData,
                                api_key: e.target.value
                            })}}
                            />
                        </Col>
                    </Grid>
                </div>
                ) : ("")}
            <Button
            disabled={requestProcessing}
            onClick={handleSubmit}
            className='CreateUserBtn'
            > Criar </Button>
            </Card>
        </div>
      </div>
    </main>
  );
}