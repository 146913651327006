import React, { useEffect, useState } from 'react';
import DashboardPage from './pages/DashboardPage';
import OperationsPage from './pages/OperationsPage';
import ListAccessPage from './pages/Access/ListAccessPage';
import CreateUsersPage from './pages/Access/CreateUsersPage';
import UpdateUsersPage from './pages/Access/UpdateUsersPage';
import LoginPage from './pages/LoginPage';
import SearchPage from './pages/SearchPage';
import StickerPage from './pages/StickerPage';
import OrderDetailPage from './pages/OrderDetailPage';
import OccurrenceListPage from './pages/Occurrences/OccurrenceListPage';
import OccurrenceChatPage from './pages/Occurrences/OccurrenceChatPage';
import OrderBatchPage from './pages/OrderBatchPage';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Drivers } from './interfaces/AccessInterfaces';
import CreateOrganizationsPage from './pages/Access/CreateOrganizationsPage';
import CreateMercadoLivreIntegrationPage from './pages/Access/CreateMercadoLivreIntegration';
import UpdateTransporterServiceAreaPage from './pages/Access/UpdateTransporterServiceAreaPage';
import UpdateTransporterAllocationPage from './pages/Access/UpdateTransporterAllocationPage';

import { Toast, ToastProvider } from "./components/Toast"
import { Toaster } from "./components/Toaster"
import { useToast } from "./lib/useToast"


export default function App() {
  const { toast } = useToast()
  const [cookies] = useCookies(['mazelog-user']);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<Drivers>({});
  const [selectedOrders, setSelectedOrders] = useState([]);

  const getDrivers = () => {
    const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;
    axios.get(`${baseURL}/users/transporters`,
    {headers: {"Authorization": "Bearer " + cookies["mazelog-user"]?.token_data?.access_token}})
    .then((res) => {
      setDrivers(res.data.transporters)
    })
    .catch(err =>
      console.log(err)
    )
  }

  useEffect(()=>{
    setIsLoggedIn(cookies["mazelog-user"]?.organization_data?.profile === 'CORP');
    getDrivers();
  },[cookies])

  return (
    <div  className="App">
      {isLoggedIn ? 
      <Router basename={process.env.PUBLIC_URL}>
        <ToastProvider>
        <Routes>
          <Route path="/" element={<DashboardPage />} />

          <Route path="/search" element={
            <SearchPage
              drivers={drivers}
              selectedOrders={selectedOrders}
              setSelectedOrders={setSelectedOrders}
            />
          } />

          <Route
            path="/orders"
            element={<StickerPage orderIds={selectedOrders} />}
          />

          <Route path="/order/:id" element={<OrderDetailPage drivers={drivers} />} />

          <Route path="/operations" element={<OperationsPage drivers={drivers}/>} />

          <Route path="/access" element={<ListAccessPage />} />

          <Route path="/users/create" element={<CreateUsersPage />} />

          <Route path="/users/:id" element={<UpdateUsersPage />} />

          <Route path="/organizations/create" element={<CreateOrganizationsPage />} />

          <Route path="/organizations/mercado_livre" element={<CreateMercadoLivreIntegrationPage />} />

          <Route path="/transporter_service_area/:id" element={<UpdateTransporterServiceAreaPage />} />

          <Route path="/transporter_allocation/:id" element={<UpdateTransporterAllocationPage />} />

          <Route path="/occurrence" element={<OccurrenceListPage />} />

          <Route path='/occurrence/:id' element={<OccurrenceChatPage />} />

          <Route
            path="/batches"
            element={
              <OrderBatchPage setSelectedOrders={setSelectedOrders}/>
            }
          />

          {/*<Route path="*" element={<NotFound />} />*/}
        </Routes>
        </ToastProvider>
      </Router>
      :
      <LoginPage/>
      }
    </div>
  );
};
