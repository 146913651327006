import React, { useEffect, useState } from 'react';
import './OrderDetailPage.css';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from "../components/TitleComponent";
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { FullOrder, OrderEvents, OrderSources } from '../interfaces/OrderInterfaces';
import { Drivers } from '../interfaces/AccessInterfaces';
import OrderComponent from '../components/OrderComponent';
import {
    Card,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableHeaderCell,
    Metric,
    Button,
    Flex,
    Grid,
    Col,
    Divider,
    Title,
    Text,
} from '@tremor/react';
import PDFStickerComponent from '../components/stickerComponents/MazelogStickerComponent';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Link } from "react-router-dom";

export default function OrderDetailPage({drivers}:{drivers:Drivers}) {
    const [cookies] = useCookies(['mazelog-user']);
    const productFlag = cookies['mazelog-user']?.organization_data?.product_flag
    const token = cookies['mazelog-user']?.token_data?.access_token
    const oauth = "Bearer " + token

    const statusTranslate: { [key: string]: string } = {
        "created": "Criado",
        "transporter_allocated": "Transportador alocado",
        "collected": "Coletado",
        "received": "Recebido",
        "en_route": "Em rota",
        "delivered": "Entregue",
        "cancelled": "Cancelado",
        "handling": "Em tratativa",
        "awaiting_return": "Aguard. devolução",
        "awaiting_transfer": "Aguard. transferência",
        "return_route": "Rota devolução",
        "returned": "Devolvido",
        "collected_not_received": "Coletados Não Receb.",
        "cancelled_after_received": "Canc. após Receb.",
        "returned_area_not_serviced": "Devolvido - Área não atendida",
        "new_driver_allocated": "Novo entregador alocado"
    }

    const meliStatusTranslate: { [key: string]: string } = {
        "handling": "Em manejo",
        "ready_to_ship": "Pronto para envio",
        "shipped": "A caminho",
        "first_visit": "Primeira visita",
        "delivered": "Entregue"
    }

    let { id } = useParams();
    const [order, setOrder] = useState<FullOrder>();
    const [orderUpdated, setOrderUpdated] = useState<boolean>(false);
    const [meliTagDownloading, setMeliTagDownloading] = useState<boolean>(false);
    const [meliOrderDelivered, setMeliOrderDelivered] = useState<boolean>(false);

    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

    const navigate = useNavigate();
    useEffect(()=>{
        axios.get(`${baseURL}/order/${id}`,{headers: {"Authorization": oauth}})
            .then((res) => {
                console.log(res);
                setOrder(res.data);
            })
            .catch((err) => {
                console.log(err);
                navigate(`/search`);
            })
    },[id,orderUpdated])

    useEffect(() => {
        if (!!order?.mercado_livre) {
            setMeliOrderDelivered(
                order.mercado_livre.events.filter(x => x.type === "delivered").length === 1
            )
        }
    }, [order])

    const downloadMercadoLivreStickerTag = () => {
        setMeliTagDownloading(true);
        axios.post(`${baseURL}/mercado_livre/tag`,
            {order_id: id},
            {
                headers: {
                    "Authorization": oauth,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            })
            .then((res:any) => {
                var urlCreator = window.URL || window.webkitURL;
                let imageUrl = urlCreator.createObjectURL(res.data);
                window.open(imageUrl, '_blank', 'noopener,noreferrer');
            })
            .catch(async (err) => {
                const response = await err.response.data.text();
                const errorData = JSON.parse(response);
                alert(`Erro na geração da etiqueta! ${errorData.detail}`)
                }
            )
            .finally(() => setMeliTagDownloading(false))
    }

    const deleteOrder = async () => {
        if (window.confirm("Deseja mesmo apagar esse pedido?")){
            axios.delete(`${baseURL}/order/${id}/delete`,{headers: {"Authorization": oauth}})
            .then((res) => {
                console.log(res);
                navigate(`/`);
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }

    const deleteOrderStatus = async (event:OrderEvents) => {
        if (window.confirm("Deseja mesmo remover esse status?")){
            axios.put(`${baseURL}/order/${id}/status/mistake`,
            {mistake: event},
            {headers: {"Authorization": oauth}})
            .then((res) => {
                console.log(res);
                setOrderUpdated(true);
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }

    const convertDate = (date:string, subtract_hours:number) => {
        const pos = date.indexOf("+");
        if (pos !== -1) {
            date = date.substring(0, pos);
        }
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() - subtract_hours);
        return newDate.toLocaleString('pt-BR');
    }

  return (
    <main>
        <SidebarComponent />
        <div className='OrderContent'>
            <TitleComponent />
            <div className='ButtonBlock'>
                <Flex justifyContent='end'>
                    {order ? 
                    (<div>
                        {order.source === OrderSources.MAZELOG ?
                            (<Button className='DownloadBtn'>
                                <PDFDownloadLink
                                    document={<PDFStickerComponent orderList={[order]} productFlag={productFlag} />}
                                    fileName={`${order?.orderNumber}.pdf`}
                                >
                                Baixar etiqueta
                                </PDFDownloadLink>
                            </Button>) :
                            (
                                <Button
                                    className='DownloadBtn'
                                    onClick={downloadMercadoLivreStickerTag}
                                    loading={meliTagDownloading}
                                    disabled={meliOrderDelivered}
                                >Baixar etiqueta Mercado Livre</Button>
                            )}
                        <Link to={`/occurrence/${order.orderNumber}`}>
                            <Button className='DownloadBtn'>Abrir ocorrência</Button>
                        </Link>
                    </div>
                    ) :
                    (<div>
                        <Button className='DownloadBtn' disabled={true}>Baixar etiqueta</Button>
                        <Button className='DownloadBtn' disabled={true}>Abrir ocorrência</Button>
                    </div>
                    )}
                    <Button className='DownloadBtn' onClick={deleteOrder}>Apagar</Button>
                </Flex>
            </div>
            <div className="DashboardViews">
                <OrderComponent order={order} />
            </div>
            <div>
                <Card>
                    <Metric>Histórico do pedido</Metric>
                    <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Status</TableHeaderCell>
                            <TableHeaderCell>Data</TableHeaderCell>
                            <TableHeaderCell>Entregador</TableHeaderCell>
                            <TableHeaderCell>Remover</TableHeaderCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { order?.events
                            .map((event) => (
                                <TableRow key={ event?.type }>
                                <TableCell className="TableField">{ statusTranslate[event?.type] }</TableCell>
                                <TableCell className="TableField">{ convertDate(event?.date, 3) }</TableCell>
                                <TableCell className="TableField">{ (event?.id_user) && (event?.id_user in drivers) ? drivers[event?.id_user].email : "" }</TableCell>
                                {['created','transporter_allocated'].includes(event?.type) ? "" :
                                    <Button onClick={() => {deleteOrderStatus(event)}} className='DeleteStatusBtn'/>}
                                </TableRow>
                            )) }
                    </TableBody>
                </Table>
                </Card>
            </div>
            <br/>
            <div>
                <div >
                    {(order)&&(order.source === OrderSources.MAZELOG) ? 
                    (<div>
                        <Card>
                            <Metric>Etiqueta</Metric>
                            <PDFViewer className='PDFCard' showToolbar={false}>
                                <PDFStickerComponent orderList={[order]} productFlag={productFlag} />
                            </PDFViewer>
                        </Card>
                    </div>) :
                    ('')}
                </div>
                <div>
                    {(order?.source === OrderSources.MERCADO_LIVRE && !!order.mercado_livre) ?
                    (<div>
                        <Card>
                            <Metric>Informações do pedido do Canal</Metric>
                            <Grid numCols={2} className="gap-2">
                                <Col numColSpan={1}>
                                    <Title>Canal</Title>
                                    <Text className='max-w-md'>Mercado Livre</Text>
                                </Col>
                                <Col numColSpan={1}>
                                    <Title>ID do pedido</Title>
                                    <Text className='max-w-md'>{order?.mercado_livre?.order_id}</Text>
                                </Col>
                                <Col numColSpan={1}>
                                    <Title>ID da entrega</Title>
                                    <Text className='max-w-md'>{order?.mercado_livre?.shipping_id}</Text>
                                </Col>
                                <Col numColSpan={1}>
                                    <Title>Seller ID</Title>
                                    <Text className='max-w-md'>{order?.mercado_livre?.seller_id}</Text>                  
                                </Col>
                                <Col numColSpan={1}>
                                    <Title>Substatus do pedido</Title>
                                    <Text>{order?.mercado_livre?.order_status}</Text>
                                </Col>
                                <Col numColSpan={1}>
                                    <Title>Descrição do serviço</Title>
                                    <Text>{order?.mercado_livre?.serviceDescription}</Text>
                                </Col>
                                <Col numColSpan={1}>
                                    <Title>Data de criação</Title>
                                    <Text>{
                                    order?.mercado_livre?.date_created ?
                                    convertDate(order?.mercado_livre?.date_created.toString(), 0):
                                    ""}</Text>
                                </Col>
                                <Col numColSpan={1}>
                                    <Title>Data de entrega prevista</Title>
                                    <Text>{
                                    order?.mercado_livre?.estimated_delivery_date ?
                                    convertDate(order?.mercado_livre?.estimated_delivery_date?.toString(), 0).substring(0, 10) :
                                    ""}</Text>
                                </Col>
                            </Grid>
                            <Divider/>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderCell>Status</TableHeaderCell>
                                        <TableHeaderCell>Data</TableHeaderCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    { order?.mercado_livre?.events
                                        .map((event:any) => (
                                            <TableRow key={ event?.type }>
                                            <TableCell className="TableField">{ meliStatusTranslate[event?.type] }</TableCell>
                                            <TableCell className="TableField">{ convertDate(event?.date, 0) }</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Card>
                    </div>) : ('')}
                </div>
            </div>
            <br/>
            <div>
                {(!!order?.collecterImage) ?
                <Card>
                    <Metric>Imagem da entrega</Metric>
                    <img src={order?.collecterImage} className='max-h-[600px] max-w-[600px]'/>
                </Card>
                 :
                ""
                }
            </div>
        </div>
    </main>
  );
}