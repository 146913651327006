import React, { useState, useEffect } from 'react';
import './UpdateUsersPage.css';
import { Button, Text, Grid, Col, Title, TextInput, Flex, Card, Dropdown, DropdownItem } from "@tremor/react";
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Organization, TransporterAllocation } from '../../interfaces/AccessInterfaces';
import SidebarComponent from '../../components/SidebarComponent';
import TitleComponent from "../../components/TitleComponent";

export default function UpdateTransporterAllocationPage() {
  const [cookies] = useCookies(['mazelog-user']);
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;
  const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;

  let { id } = useParams();

  const EmptyOrganization: Organization = {
    name: "",
    cnpj: "",
    prefix: "",
    product_flag: false,
    profile: "TRANSPORTER",
    whatsapp_flag: false,
    email_flag: false,
    spreadsheet: false,
    correios: false,
  }

  const [requestProcessing, setRequestProcessing] = useState(false);
  const [transporterAllocation, setTransporterAllocation] = useState<TransporterAllocation>();
  const [transporters, setTransporters] = useState<Organization[]>([EmptyOrganization]);

  const navigate = useNavigate();
  const getOrgTransporterData = () => {
    axios.get(`${baseURL}/transporters/transporter_allocation?id_org=${id}`,
        {headers: {
          "Content-Type": "application/json",
          "Authorization": oauth,
        }})
    .then((res) => {
      setTransporterAllocation(res.data)
    })
    .catch((err)=>{
        alert('Erro!');
        navigate('/access')
    });
  }

  const getAllTransporters = () => {
    const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;
    const oauth = "Bearer " + token;
    axios.get(`${baseURL}/transporters`,
      {headers: {accept: 'application/json', "Authorization": oauth}}
    )
    .then((res)=>{
      console.log(res);
      setTransporters([EmptyOrganization].concat(res.data.data));
    })
    .catch((err)=>{
      console.log(err);
    })
}

  const updateTransporterAllocation = () => {
    setRequestProcessing(true);
    axios.put(`${baseURL}/transporters/transporter_allocation`,
      transporterAllocation,
      {headers: {
        "Content-Type": "application/json",
        "Authorization": oauth,
      }})
    .then((res) => {
      alert("Transportadores alocados atualizados com sucesso!");
      navigate('/access')
    })
    .catch((err)=>{
      alert(`Erro! ${err.response?.data?.detail}`);
      setRequestProcessing(false);
    });
  }

  const updateTransporterPriority = (rowIndex:number, newValue:string[]) => {
    if (!!transporterAllocation) {
      setTransporterAllocation({
          ...transporterAllocation,
          transporter_allocation: transporterAllocation.transporter_allocation.map(
              (value, idx) => (idx === rowIndex) ? newValue : value
          )
      })
    }
  }

  useEffect(()=>{
    getOrgTransporterData();
    getAllTransporters();
  },[])

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="CreateUserViews">
            <Card>
                <Title>Atualizar alocação de transportadoras</Title>
                {!transporterAllocation ? "" :
                <Grid numCols={2} className="gap-x-0 gap-y-1">
                    <Col numColSpan={1}>
                        <Title>ID Organização</Title>
                        <Text>{transporterAllocation.id_org.toString()}</Text>
                    </Col>
                    
                    <Col numColSpan={2}>
                    <Title>Prioridade de transportadoras</Title>
                    { transporterAllocation.transporter_allocation
                    .map((transporter_schedule, index) => (
                        <Flex justifyContent='start'>
                          <Text>{index + 1}ª prioridade</Text>
                          <Dropdown
                            className='max-w-xs'
                            value={transporter_schedule[0]}
                            onValueChange={(value) => {
                              updateTransporterPriority(
                                index,
                                [value, transporter_schedule[1], transporter_schedule[2]]
                              )
                            }}
                          >
                          {transporters.map((transporter) => (
                              <DropdownItem
                                key={transporter.name}
                                value={transporter.name}
                                text={transporter.name}
                                />))}
                          </Dropdown>
                          <Dropdown
                            className='max-w-xs'
                            value={transporter_schedule[1]}
                            onValueChange={(value) => {
                              updateTransporterPriority(
                                index,
                                [transporter_schedule[0], value, transporter_schedule[2]]
                              )
                            }}
                          >
                          {transporters.map((transporter) => (
                              <DropdownItem
                                key={transporter.name}
                                value={transporter.name}
                                text={transporter.name}
                                />))}
                          </Dropdown>
                          <Dropdown
                            className='max-w-xs'
                            value={transporter_schedule[2]}
                            onValueChange={(value) => {
                              updateTransporterPriority(
                                index,
                                [transporter_schedule[0], transporter_schedule[1], value]
                              )
                            }}
                          >
                          {transporters.map((transporter) => (
                              <DropdownItem
                                key={transporter.name}
                                value={transporter.name}
                                text={transporter.name}
                                />))}
                          </Dropdown>
                        </Flex>
                    )) }
                    </Col>
                    <Col numColSpan={1}>
                    <Button
                      loading={requestProcessing}
                      onClick={updateTransporterAllocation}
                      className='OpenStickerButton'
                      > Atualizar </Button>
                    </Col>
                </Grid>}
            </Card>
        </div>
      </div>
    </main>
  );
}